import { ref, watch, computed } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default function useUsersList() {
    // Use toast
    const toast = useToast();

    const refShopListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        {
            key: "logo",
            sortable: false,
        },
        {
            key: "name",
            sortable: false,
        },
        {
            key: "total",
            sortable: false,
            formatter: val => `$${val.toLocaleString()}`,
        },
        {
            key: "api_key",
            sortable: false,
            label: "API KEY",
        },
        {
            key: "createDate",
            sortable: false,
        },
        {
            key: "actions",
            sortable: false,
        },
    ];
    const perPage = ref(10);
    const totalUsers = ref(null);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const tableLoading = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refShopListTable.value ? refShopListTable.value.localItems.length : 0;

        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        };
    });

    const refetchData = () => {
        refShopListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    const fetchShops = (ctx, callback) => {
        store
            .dispatch("admin-shop/fetchShops", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                role: roleFilter.value,
                plan: planFilter.value,
                status: statusFilter.value,
            })
            .then(response => {
                const { shops, total } = response.data;
                tableLoading.value = false;
                callback(shops);
                totalUsers.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching shop list",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === "subscriber") {
            return "primary";
        }

        if (role === "author") {
            return "warning";
        }

        if (role === "maintainer") {
            return "success";
        }

        if (role === "editor") {
            return "info";
        }

        if (role === "admin") {
            return "danger";
        }

        return "primary";
    };

    const resolveUserRoleIcon = role => {
        if (role === "subscriber") {
            return "UserIcon";
        }

        if (role === "author") {
            return "SettingsIcon";
        }

        if (role === "maintainer") {
            return "DatabaseIcon";
        }

        if (role === "editor") {
            return "Edit2Icon";
        }

        if (role === "admin") {
            return "ServerIcon";
        }

        return "UserIcon";
    };

    const resolveUserStatusVariant = status => {
        if (status === "pending") {
            return "warning";
        }

        if (status === "active") {
            return "success";
        }

        if (status === "inactive") {
            return "secondary";
        }

        return "primary";
    };

    return {
        fetchShops,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refShopListTable,
        tableLoading,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        refetchData,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    };
}
