import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchShops(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/shop/", {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchShop(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/shop/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        syncShop(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/sync/woocommerce/orders", {
                        id,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addShop(ctx, shopData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/shop/", {
                        shop: shopData,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
    },
};
